import Vue from 'vue'
import VueQrcodeReader from 'vue-qrcode-reader'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import '/~/boot/packages'
import '/~/boot/mixins'
import 'swiper/css/bundle'
import '/~/configurator/components'
import { useCart } from '/~/composables/cart'
import { useEwallet } from '/~/composables/ewallet'
import { useExtensions } from '/~/composables/extensions'
import { useNotifications } from '/~/composables/notifications'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import { useUser } from '/~/composables/user'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import modals from './modals'

modal.register(modals)

async function bootstrap() {
  const { fetchBalance: fetchPointsBalance } = usePoints()
  const { fetchBalance: fetchEwalletBalance } = useEwallet()
  const { fetchPaymentMethods, getPaymentMethodsConfig } = usePaymentMethods()
  const { fetchCart } = useCart()
  const { initNotifications } = useNotifications()
  const { getManifestByName, initExtensions } = useExtensions()
  const { isClaimsEnabled } = useProvider()
  const { initPurchases } = usePurchases()
  const { fetchUserClaimStatus } = useUser()
  const { initAirPartners } = usePointsPrograms()

  Vue.notify({ clean: true })

  if (isClaimsEnabled.value) {
    fetchUserClaimStatus()
  }

  const isQRScan = Boolean(
    getManifestByName('qr-pay') || getManifestByName('qrconnect')
  )

  if (isQRScan) {
    Vue.use(VueQrcodeReader)
  }

  import('/~/boot/provider/override/pipelines')

  initExtensions()?.then(() => {
    initNotifications()
    initAirPartners()
  })

  initPurchases()
  fetchCart()

  getPaymentMethodsConfig().then(() => {
    fetchPointsBalance()
    fetchEwalletBalance()
  })
  fetchPaymentMethods()

  import('/~/boot/zendesk')
  import('/~/boot/google-analytics')
}

if (eonx.user) {
  bootstrap()
} else {
  emitter.once('auth:success', bootstrap)
}
